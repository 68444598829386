<script setup lang="ts">
export type ColorVariant = 'standard' | 'on-primary-bg' | 'on-accent-bg'

export interface CheckboxProps {
  name?: string
  label?: string
  disabled?: boolean
  modelValue?: any
  error?: string
  colorVariant?: ColorVariant
  isLoading?: boolean
}
const props = withDefaults(defineProps<CheckboxProps>(), {
  colorVariant: 'standard',
  isLoading: false,
})

const emit = defineEmits(['update:modelValue', 'click'])

const modelValue = useVModel(props, 'modelValue', emit)

function handleClick (event: Event) {
  event?.stopPropagation()

  if (!props.disabled) {
    modelValue.value = !modelValue.value
    emit('click')
  }
}
</script>

<template>
  <div class="group" flex items-start gap-6px cursor-pointer @click="handleClick" @keypress="handleClick">
    <div
      :data-test="getCypressId('checkbox')"
      w-20px h-20px flex justify-center items-center flex-shrink-0 rounded-2px
      border-1px mt-2px
      focus:fubex-focus-ring
      :border="['on-primary-bg', 'on-accent-bg'].includes(colorVariant) ? 'black dark:white' : 'neutral-500'"

      :class="{
        'group-hover:border-neutral-500': !props.disabled && colorVariant !== 'on-accent-bg',
        'bg-primary-500 dark:bg-primary-200 border-none group-hover:bg-primary-600 dark:group-hover:bg-primary-300': modelValue && !props.disabled && !['on-primary-bg', 'on-accent-bg'].includes(colorVariant),
        'bg-primary-700 dark:bg-primary-200 border-none group-hover:bg-primary-600 dark:group-hover:bg-primary-300': modelValue && colorVariant === 'on-primary-bg' && !props.disabled,
        'bg-accent-700 dark:bg-accent-200 border-none group-hover:bg-accent-600 dark:group-hover:bg-accent-300': modelValue && colorVariant === 'on-accent-bg',
        'bg-neutral-200 border-none': props.disabled && modelValue && colorVariant !== 'on-primary-bg',
        'bg-primary-300 dark:bg-primary-100 border-none': props.disabled && modelValue && colorVariant === 'on-primary-bg',
        'bg-none border-1 border-neutral-300 group-hover:border-neutral-300': props.disabled && !modelValue,
      }"

      tabindex="0"
    >
      <UnoIcon v-if="modelValue && !isLoading" i-fubex-check text-neutral-50 h-10px w-20px :class="{ 'dark:text-neutral-900': !props.disabled }" />
      <FLoadingCircular :is-loading="isLoading" />
    </div>
    <div v-auto-animate flex flex-col :class="{ 'text-neutral-300': props.disabled }">
      <p v-if="label" paragraph-md>
        {{ label }}
      </p>
      <div paragraph-md :class="props.disabled ? 'text-neutral-300' : ['on-primary-bg', 'on-accent-bg'].includes(colorVariant) ? 'text-black dark:text-white' : '' ">
        <slot />
      </div>
      <div v-if="error" text-error-default paragraph-md>
        {{ error }}
      </div>
    </div>
  </div>
</template>
