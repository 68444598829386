<script setup lang="ts">
const props = defineProps<{
  isLoading: boolean
  size?: 'sm' | 'md' | 'lg'
}>()

const sizeClass = computed(() => {
  switch (props.size) {
    case 'sm':
      return 'w-12px h-12px'
    case 'lg':
      return 'w-24px h-24px'
    case 'md':
    default:
      return 'w-16px h16px'
  }
})
</script>

<template>
  <div v-if="isLoading" class="flex justify-center items-center">
    <div :class="['relative', sizeClass]">
      <div class="absolute inset-0 rounded-full border-2 border-t-primary-500 border-b-transparent animate-spin" />
    </div>
  </div>
</template>
